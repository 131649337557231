<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span class="title">查詢條件</span>
      </div>

      <!-- 查詢條件 -->
      <el-form
        ref="form"
        :model="queryData"
        label-width="80px"
        label-position="left"
      >
        
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="群組名稱">
              <el-input
                v-model="queryData.serviceGroupTeamName"
                placeholder="請輸入群組名稱"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :sm="23" class="btn-col">
            <el-button type="primary" @click="query">搜尋</el-button>
            <el-button plain @click="restoreDefault">清除</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <br />

    <el-card>
      <div slot="header" class="clearfix" style="line-height: 40.5px">
        <span class="title">查詢結果</span>
        <el-button style="float: right" type="primary" @click="add"
          >新增</el-button
        >
      </div>

      <el-table
        :data="tableData"
        border
        stripe
        empty-text="暫無數據"
        style="width: 100%"
      >
        <!-- 群組名稱 -->
        <el-table-column
          fixed
          prop="name"
          label="權限名稱"
          min-width="300"
          align="center"
        >
        </el-table-column>

        <!-- 建立者 -->
        <el-table-column
          prop="createdBy"
          label="建立者"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 建立日期 -->
        <el-table-column
          prop="dateCreatedStr"
          label="建立日期"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 編輯者 -->
        <el-table-column
          prop="lastUpdatedBy"
          label="編輯者"
          width="200"
          align="center"
        ></el-table-column>

        <!-- 編輯日期 -->
        <el-table-column
          prop="lastUpdatedStr"
          label="編輯日期"
          width="200"
          align="center"
        >
        </el-table-column>

        <!-- 操作 -->
        <el-table-column label="操作" width="290" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              v-on:click="edit(scope.row.serviceGroupTeamId)"
            >
              檢視與編輯
            </el-button>
       
          </template>
        </el-table-column>
      </el-table>

      <br />

    </el-card>

   
  </div>
</template>

<script>
import API from "../../api"
import Utils from "../../utils";
import { Message } from "element-ui"


export default {
  name: "GroupTeamManage",
 

  data() {
    return {
      isLoading: false,
      queryData: {
        serviceGroupTeamName: null,
      },
     
      tableData: [],
  
    }
  },
 

  created() {
   
      this.query();
  },

  methods: {
    // 新增群組功能
    add() {
        this.$router.push({ path: `/groupTeamManage/add` });
    },

    //編輯群組功能
    edit(id) {
       this.$router.push({
          path: `/groupTeamManage/edit`,
          query: { editId: `${id}` },
        });
    },

    /**查詢條件 恢復預設值 */
    restoreDefault() {
      this.queryData.serviceGroupTeamName = null
    },

    //權限查詢
    query() {
      this.isLoading = true
      API.groupTeam
        .query(this.queryData)
        .then((res) => {
          this.tableData = res.data.results
          this.isLoading = false
        })
        .catch((e) => {
          console.log(`serviceGroupTeam query error`)
          this.$message.error("查詢失敗!")
          this.isLoading = false
        })
    },


  },
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.el-form .el-select {
  width: 95% !important;
}
.form-range {
  display: inline-block;
}
.form-range.el-select {
  width: 30% !important;
}
.form-range-to {
  margin: 0 10px;
}
.btn-col {
  text-align: right;
}
.btn-col .el-button,
.btn-new .el-button {
  width: 96px;
}
.btn-new {
  float: right;
}


</style>